import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col, Button } from 'react-bootstrap'

import hero from '../../assets/image/hero-qpon.jpg'

const Hero = () => {
  return (
    <div className="pb-16 pb-lg-22 pt-25 pt-lg-29 bg-default-1 position-relative">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col
            sm="10"
            md="9"
            lg="7"
            xl="6"
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-delay="500"
          >
            <div className="hero-content text-center">
              <h1 className="gr-text-3 mb-8">
                Customer loyalty rewards via Facebook Messenger
              </h1>
              <p className="gr-text-8">
                Qpon helps you quickly and easily connect with and reward
                returning customers on Facebook Messenger.
              </p>
              <div className="hero-btns d-flex flex-column flex-md-row justify-content-md-center mt-11">
                <Button
                  as={Link}
                  to="/qpon#request-demo"
                  className="btn btn-primary with-icon gr-hover-y mb-6 mb-md-0 mr-md-7"
                >
                  Request a free demo
                  <i className="fas fa-arrow-right gr-text-11"></i>
                </Button>
                <Link className="btn btn-white gr-hover-y" to="/qpon#content">
                  Learn more
                </Link>
              </div>
            </div>
          </Col>
          <Col sm="10" className="mt-15 mt-lg-23">
            <div
              className="hero-img d-flex justify-content-center"
              data-aos="fade-left"
              data-aos-duration="750"
              data-aos-delay="500"
            >
              <img
                className="img-fluid rounded"
                src={hero}
                alt="girl behind facebook messenger"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Hero
