import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'

const CTA = () => {
  return (
    <div
      id="request-demo"
      className="cta-section overflow-hidden py-13 py-lg-18 bg-mirage bg-pattern pattern-2"
    >
      <Container>
        <Row className="align-items-center">
          <Col xl="5" md="6" data-aos="fade-right" data-aos-duration="800">
            <div className="section-title pr-lg-7 mb-0 dark-mode-texts">
              <div className="chat-icon circle-xl gr-bg-white-opacity-1 text-green gr-text-4 mb-13">
                <i className="icon icon-chat-round-2"></i>
              </div>
              <h2 className="title gr-text-4 mb-6">
                Want to learn more about this effective sales generation tool?
                Reach out to our team.
              </h2>
            </div>
          </Col>
          <Col
            xl="4"
            lg="5"
            md="6"
            className="offset-xl-3 offset-lg-1 mt-9 mt-md-0"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <form
              className="cta-form bg-white light-mode rounded-10 px-8 py-8"
              action="https://formspree.io/f/mvovqrek"
              method="POST"
            >
              <input type="text" name="_gotcha" style={{ display: 'none' }} />
              <input type="hidden" name="_subject" value="Qpon Demo Request" />
              <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                <label htmlFor="ctaName">Name </label>
                <input
                  type="text"
                  className="form-control gr-text-11"
                  name="name"
                  placeholder="Tony Stark"
                  required
                />
              </div>
              <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                <label htmlFor="ctaEmail">Email </label>
                <input
                  type="email"
                  className="form-control gr-text-11"
                  name="email"
                  placeholder="tony@starkindustries.com"
                  required
                />
              </div>
              <Button type="submit" className="gr-hover-y rounded-8 w-100">
                Requst a free demo
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CTA
