import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import imgC from '../../assets/image/content-1-img.jpg'

const Content = () => {
  return (
    <div id="content" className="content-section py-13 pb-24 bg-default-6">
      <Container>
        <Row className="justify-content-center">
          <Col md="10" lg="8" xl="7">
            <div className="section-title text-center mb-11 mb-lg-19">
              <h2 className="gr-text-4 mb-6">Increase sales fast</h2>
              <p className="gr-text-8 px-lg-4">
                With 20 billion messages exchanged every month between
                businesses and their customers (10x growth in past 2 years),
                Facebook Messenger is the ideal tool to help drive sales growth.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col sm="10" lg="6" className="mb-10 mb-lg-0">
            <div className="content-img img-group-2">
              <img className="img-fluid" src={imgC} alt="" />
            </div>
          </Col>
          <Col sm="11" lg="6" xl="5">
            <div className="content-widget-1 pl-lg-9">
              <div className="media single-widget mb-7">
                <div className="count circle-sm gr-bg-blue-opacity-1 mr-8">
                  <span className="text-green gr-text-9">1</span>
                </div>
                <div className="media-body">
                  <h3 className="gr-text-7">Customize your program</h3>
                  <p className="gr-text-9">
                    We work with you to setup messages and help detemine rewards
                    suitable to your business. For instance, a coffee shop could
                    reward a free coffee after 6 purchases.
                  </p>
                </div>
              </div>
              <div className="media single-widget mb-7">
                <div className="count circle-sm gr-bg-blue-opacity-1 mr-8">
                  <span className="text-green gr-text-9">2</span>
                </div>
                <div className="media-body">
                  <h3 className="gr-text-7">Promote reward opportunities</h3>
                  <p className="gr-text-9">
                    Inform current and potential customers about your loyalty
                    rewards program. We also market your program via our custom
                    marketing channels.
                  </p>
                </div>
              </div>
              <div className="media single-widget mb-7">
                <div className="count circle-sm gr-bg-blue-opacity-1 mr-8">
                  <span className="text-green gr-text-9">3</span>
                </div>
                <div className="media-body">
                  <h3 className="gr-text-7">Hands-free customer messaging</h3>
                  <p className="gr-text-9">
                    Customers will sign up for your rewards program without any
                    effort on your part. Your customers interact with your
                    business anytime with the same personalized, rich-media
                    experience they get in Messenger.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Content
