import React from 'react'

import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/qpon/Hero'
import Features from '../sections/qpon/Features'
import Content from '../sections/qpon/Content'
import CTA from '../sections/qpon/CTA'

const SaasSubscription = () => {
  return (
    <PageWrapper
      headerConfig={{
        align: 'right',
        button: 'null', // cta, account, null
      }}
      footerConfig={{
        theme: 'light',
        style: 'style1',
      }}
      seoConfig={{
        title: 'Qpon',
      }}
    >
      <Hero />
      <Features />
      <Content />
      <CTA />
    </PageWrapper>
  )
}
export default SaasSubscription
