import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import iconF1 from '../../assets/image/l1-feature-icon1.svg'
import iconF2 from '../../assets/image/l1-feature-icon2.svg'
import iconF3 from '../../assets/image/l1-feature-icon3.svg'

const Features = () => {
  return (
    <div className="feature-section bg-default-1 pb-4 pb-lg-13">
      <Container>
        <Row className="border-bottom pb-lg-19">
          <Col sm="6" lg="4" className="mb-8">
            <div className="feature-widget-1">
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <div className="icon-wrap">
                  <img src={iconF1} alt="" />
                </div>
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7">Automation in mind</h5>
                  <p className="gr-text-9">
                    Drive sale efficiency by automating upsells and reviews with
                    use case-specific bots.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div className="feature-widget-1">
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF2} alt="" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7">Returning customers</h5>
                  <p className="gr-text-9">
                    Instill customer loyalty in your business to keep customers
                    coming back to purchase more.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div className="feature-widget-1">
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF3} alt="" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7">Facebook Messenger</h5>
                  <p className="gr-text-9">
                    Leverage a conversation tool your customers already use,
                    Facebook Messenger.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Features
